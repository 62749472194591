<template>
  <div class="feed-container mx-auto">
    <b-row class="no-gutters">
      <b-col class="posts-container">
        <PostItem
          v-for="(post, index) in posts"
          :key="index"
          :post="post"
          ref="posts"
        />
      </b-col>
      <b-col class="col-4 pt-4 suggestions-container">
        <Suggestions />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PostItem from "./PostItem.vue";
import Suggestions from "./Suggestions.vue";

import { postsListComponent } from "../../mixins/instagramFacebookViewers";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    PostItem,
    Suggestions,
  },
  mixins: [postsListComponent],
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("instagram", {
      posts: "getPosts",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
    }),
  },
  methods: {
    ...mapActions("stimulus", [
      "resetStimulusImages",
      "setSocialsFirstSurveyStepImgCount",
    ]),
    ...mapActions("instagram", ["getPosts"]),
    ...mapMutations("instagram", [
      "SET_SURVEY_STEP",
      "SET_SHOW_PROMPT",
      "SET_PLAY_STIMULUS",
      "SET_SCROLL_POSITION",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/instagram-styles.scss";
.feed-container {
  max-width: 1030px;
}

.posts-container {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  // Mobile Only
  .posts-container {
    margin-top: 16px;
  }
}

@media screen and (min-width: 1160px) {
  // Suggestions NOT hidden
  .posts-container {
    margin-right: 64px;
  }
}

@media screen and (max-width: 1160px) {
  // Suggestions hidden
  .feed-container {
    max-width: 630px;
  }
  .suggestions-container {
    display: none;
  }
}
</style>
