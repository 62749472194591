<template>
  <div>
    <b-row class="no-gutters py-2">
      <b-col class="col-auto">
        <img
          src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/7QCcUGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAIAcAmcAFE9vXzRoLVVyb3FkdWlkc2NkVktwHAIoAGJGQk1EMGEwMDBhODIwMTAwMDBjNzAxMDAwMDA0MDIwMDAwMjcwMjAwMDA1MTAyMDAwMDg4MDIwMDAwYjYwMjAwMDBlMzAyMDAwMDA5MDMwMDAwMzcwMzAwMDA4MTAzMDAwMP/bAEMABgQFBgUEBgYFBgcHBggKEAoKCQkKFA4PDBAXFBgYFxQWFhodJR8aGyMcFhYgLCAjJicpKikZHy0wLSgwJSgpKP/bAEMBBwcHCggKEwoKEygaFhooKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKP/CABEIACgAKAMBIgACEQEDEQH/xAAaAAEBAQADAQAAAAAAAAAAAAAABAUBAgMG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfue/S2zmPZgIwK5PY1c/Qyo8RVtxKBCLP/EAB0QAAICAgMBAAAAAAAAAAAAAAECAxAABBETITH/2gAIAQEAAQUCxVLHo8YFTWtWz9rXb3NhuWqJSWyVSGyKMEXLGAP/xAAUEQEAAAAAAAAAAAAAAAAAAAAw/9oACAEDAQE/AR//xAAWEQADAAAAAAAAAAAAAAAAAAAAATD/2gAIAQIBAT8Bgj//xAAcEAACAwADAQAAAAAAAAAAAAAQEQABAiAhMVH/2gAIAQEABj8Ciqe9xWdCigvhoWHrg8z/xAAdEAEAAgMAAwEAAAAAAAAAAAABEBEAITFBcaHx/9oACAEBAAE/IYmfhZSGmel6j4Zob8xQTIwaHbCMml0wY3XwwKKISynHGqumf//aAAwDAQACAAMAAAAQ+d++v+ig+//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAwEBPxCTCcMb/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAA/9oACAECAQE/EHDHEa//xAAeEAEAAQUBAQEBAAAAAAAAAAABEQAQITFBUWFxkf/aAAgBAQABPxCiAk7V0FOaGwxxXqAftzoZwn+2GeOib4uRmP0tAERkv1uqqI8CLKqmPBmwlQy4x60AAAaCwIAjsaEqGXGPSv/Z"
          alt=""
          class="profile-img cursor-pointer"
        />
      </b-col>
      <b-col
        class="align-self-center pl-3 font-size-md font-weight-600 cursor-pointer"
        >{{ $t("page.instagram.profile") }}</b-col
      >
    </b-row>
    <b-row class="no-gutters mt-3">
      <b-col class="suggested font-size-md font-weight-600">{{
        $t("page.instagram.suggestedForYou")
      }}</b-col>
      <b-col
        class="col-auto see-all-btn font-size-sm font-weight-600 cursor-pointer"
        >{{ $t("page.instagram.seeAll") }}</b-col
      >
    </b-row>
    <b-row class="no-gutters copyright font-size-sm mt-3">
      <ul class="m-0 p-0">
        <li class="cursor-pointer">About •</li>
        <li class="cursor-pointer">Help •</li>
        <li class="cursor-pointer">Press •</li>
        <li class="cursor-pointer">API •</li>
        <li class="cursor-pointer">Jobs •</li>
        <li class="cursor-pointer">Privacy •</li>
        <li class="cursor-pointer">Terms •</li>
        <li class="cursor-pointer">Location •</li>
        <li class="cursor-pointer">Language •</li>
        <li class="cursor-pointer">Meta Verified</li>
      </ul>
    </b-row>
    <b-row class="no-gutters copyright font-size-sm mt-3">
      © 2023 INSTAGRAM FROM META
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/instagram-styles.scss";

.suggested {
  color: rgb(115, 115, 115);
}

.profile-img {
  border-radius: 50%;
  border: 1px solid #ced0d4;
  height: 56px;
  height: 56px;
}

.see-all-btn {
  color: rgb(0, 0, 0);
}

.copyright {
  color: rgb(199, 199, 199);
}

ul {
  li {
    display: inline-block;
    margin-right: 0.25em;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
