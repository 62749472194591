<template>
  <div class="mx-auto post" ref="post">
    <b-row class="no-gutters py-2 mx-1 header">
      <b-col class="col-auto m-auto" v-if="!isStimulus"
        ><img
          alt="poster picture"
          class="poster-img"
          :src="profilePic"
      /></b-col>
      <b-col class="ml-2 d-flex align-items-center">
        <div class="post-header-top font-size-md">
          <span class="poster-details" v-if="!isStimulus">
            <span class="poster-name cursor-pointer font-weight-600">{{
              posterName
            }}</span>
            <span class="poster-verified ml-1" v-if="verifiedAccount">
              <img
                src="../../assets/img/Instagram/verified.png"
                width="17px"
                alt="verified"
              />
            </span>
          </span>
          <span class="post-details">
            <span class="mx-1">•</span>
            <span class="posted-since">{{
              isStimulus ? "Sponsored" : postedSince
            }}</span>
          </span>
          <span class="post-actions" v-if="!isFollowed">
            <span class="mx-1">•</span>
            <span class="poster-follow cursor-pointer" @click="setIsFollowed">{{
              $t("page.instagram.follow")
            }}</span>
          </span>
        </div>
      </b-col>
      <b-col class="col-auto m-auto"
        ><svg
          aria-label="More Options"
          class="cursor-pointer options"
          color="rgb(0.0.0)"
          fill="rgb(0.0.0)"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
        >
          <circle cx="12" cy="12" r="1.5"></circle>
          <circle cx="6" cy="12" r="1.5"></circle>
          <circle cx="18" cy="12" r="1.5"></circle></svg>
        </b-col>
    </b-row>
    <b-row class="no-gutters my-1 main-content" @dblclick="isLikedDblClick">
      <div class="image-wrapper" v-if="contentType === 'feed' || contentType==='carousel_container'">
        <img :src="bodyImg" />
      </div>
      <div class="video-wrapper w-100" v-if="contentType === 'clips' || isStimulus">
        <video-player :src="bodyVideo" :isStimulus="isStimulus" />
      </div>
    </b-row>
    <b-row class="no-gutters py-2 action-buttons">
      <b-col class="col-auto">
        <svg
          aria-label="Like"
          class="mr-3 cursor-pointer"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
          v-if="!isLiked"
          @click="toggleIsLiked"
        >
          <title>Like</title>
          <path
            d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"
          ></path>
        </svg>
        <svg
          aria-label="Unlike"
          class="mr-3 cursor-pointer"
          color="rgb(255, 48, 64)"
          fill="rgb(255, 48, 64)"
          height="24"
          role="img"
          viewBox="0 0 48 48"
          width="24"
          v-if="isLiked"
          @click="toggleIsLiked"
        >
          <title>Unlike</title>
          <path
            d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"
          ></path>
        </svg>
      </b-col>
      <b-col class="col-auto"
        ><svg
          aria-label="Comment"
          class="mr-3 cursor-pointer"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
        >
          <title>Comment</title>
          <path
            d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
          ></path></svg>
        </b-col>
      <b-col class="col-auto"
        ><svg
          aria-label="Share Post"
          class="cursor-pointer"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
        >
          <title>Share Post</title>
          <line
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
            x1="22"
            x2="9.218"
            y1="3"
            y2="10.083"
          ></line>
          <polygon
            fill="none"
            points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
          ></polygon></svg>
        </b-col>
      <b-col class="text-right">
        <svg
          aria-label="Save"
          class="cursor-pointer"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
        >
          <title>Save</title>
          <polygon
            fill="none"
            points="20 21 12 13.44 4 21 4 3 20 3 20 21"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          ></polygon>
        </svg>
      </b-col>
    </b-row>
    <b-row class="no-gutters mt-1 likes-counter">
      <b-col class="cursor-pointer font-size-md font-weight-600">
        {{ reactionsCounter }}
        <span class="text-lowercase">{{ $t("page.instagram.likes") }}</span>
      </b-col>
    </b-row>
    <b-row class="no-gutters mt-2 post-text" v-if="description.length > 0">
      <b-col class="font-size-md post-description-minimized" v-if="!descriptionExpanded">
        <span class="poster-name font-weight-600 cursor-pointer mr-1">{{
          posterName
        }}</span>
        <span class="">{{ description }}</span>
        <div>
          <span><button class="showMore-btn" @click="showMore">Show more</button></span>
        </div>
      </b-col>
      <b-col class="font-size-md" v-else>
        <span class="poster-name font-weight-600 cursor-pointer mr-1">{{
          posterName
        }}</span>
        <span class="">{{ description }}</span>
        <div>
          <span><button class="showMore-btn" @click="showMore">Show less</button></span>
        </div>
      </b-col>
    </b-row>
    <b-row class="no-gutters mt-2 comments-counter">
      <b-col class="font-size-md cursor-pointer">{{
        $t("page.instagram.viewAllComments", {
          commentsCounter,
        })
      }}</b-col>
    </b-row>
    <b-row class="no-gutters mt-1 comments font-size-md" v-if="comments">
      <b-col class="col-12" v-for="(comment, index) in comments" :key="index"
        ><span class="font-weight-600 mr-1">{{
          $t("page.instagram.user")
        }}</span>
        <span>{{ comment }}</span></b-col
      >
    </b-row>
    <b-row class="no-gutters mt-1 new-comment">
      <b-col class="d-flex align-items-center">
        <textarea
          class="font-size-md"
          rows="1"
          cols="100"
          :placeholder="$t('page.instagram.addComment')"
          autocomplete="off"
          v-model="comment"
          @keydown.enter="addNewComment"
        ></textarea>
      </b-col>
      <b-col
        class="col-auto poster-follow cursor-pointer font-size-md pl-2 d-flex align-items-center"
        v-if="comment"
        @click="addNewComment"
        >{{ $t("page.instagram.post") }}</b-col
      >
      <b-col class="col-auto pl-2 d-flex align-items-center">
        <svg
          class=""
          aria-label="Emoji"
          color="rgb(100,100,100)"
          fill="rgb(100,100,100)"
          height="13"
          role="img"
          viewBox="0 0 24 24"
          width="13"
        >
          <title>Emoji</title>
          <path
            d="M15.83 10.997a1.167 1.167 0 1 0 1.167 1.167 1.167 1.167 0 0 0-1.167-1.167Zm-6.5 1.167a1.167 1.167 0 1 0-1.166 1.167 1.167 1.167 0 0 0 1.166-1.167Zm5.163 3.24a3.406 3.406 0 0 1-4.982.007 1 1 0 1 0-1.557 1.256 5.397 5.397 0 0 0 8.09 0 1 1 0 0 0-1.55-1.263ZM12 .503a11.5 11.5 0 1 0 11.5 11.5A11.513 11.513 0 0 0 12 .503Zm0 21a9.5 9.5 0 1 1 9.5-9.5 9.51 9.51 0 0 1-9.5 9.5Z"
          ></path>
        </svg>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";
import { mapGetters, mapMutations } from "vuex";

import { instagramPost } from "../../mixins/instagramFacebookViewers";

export default {
  data(){
    return{
      descriptionExpanded:false,
    }
  },
  props: ["post"],
  components: { VideoPlayer },
  mixins: [instagramPost],
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("instagram", {
      surveyStep: "getSurveyStep",
    }),
  },
  methods: {
    ...mapMutations("instagram", [
      "SET_POST_IS_FOLLOWED",
      "SET_POST_IS_LIKED",
      "SET_POST_IS_VIEWED",
      "SET_POST_COMMENT",
    ]),
    setIsFollowed() {
      this.SET_POST_IS_FOLLOWED(this.postId);
    },
    isLikedDblClick() {
      if (this.bodyContentType === "image") {
        this.toggleIsLiked();
      }
    },
    showMore(){
      if(this.surveyStep!=="scroll")return
      this.descriptionExpanded = !this.descriptionExpanded
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/instagram-styles.scss";
.post {
  max-width: 470px;
  margin-bottom: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(219, 219, 219);
}
.poster-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.poster-name {
  &:hover {
    opacity: 0.5;
  }
}
.post-details {
  color: rgb(115, 115, 115);
}
.poster-follow {
  font-weight: 600;
  color: rgb(0, 149, 246);
  @media screen and (min-width: 768px) {
    &:hover {
      color: rgb(0, 55, 107);
    }
  }
}
.options {
  &:hover {
    opacity: 0.5;
  }
}
.main-content {
  border: 1px solid rgb(239, 239, 239);
  border-radius: 4px;
}
.image-wrapper {
  background-color: black;
  text-align: center;
  max-height: 584px;
  max-width: 468px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.action-buttons svg {
  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.5;
    }
  }
}
.comments-counter {
  color: rgb(115, 115, 115);
}
.new-comment textarea {
  width: 100%;
  outline: 0;
  border: 0;
  resize: none;
}
.post-description-minimized{
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* This is where the magic happens. */

  }
  .showMore-btn{
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    outline: none;
    color:grey;
  }

@media screen and (max-width: 768px) {
  // Mobile Only
  .action-buttons {
    padding: 0px 16px;
  }
  .likes-counter {
    padding: 0px 16px;
  }
  .post-text {
    padding: 0px 16px;
  }
  .comments {
    padding: 0px 16px;
  }
  .comments-counter {
    padding: 0px 16px;
  }
  .new-comment {
    padding: 0px 16px;
  }
  .new-comment {
    display: none;
  }
}
</style>
