<template>
  <div class="instagram-container h-100">
    <SocialsPrompt
      v-if="showPrompt"
      :surveyStep="surveyStep"
      :surveyStepDuration="surveyStepDuration"
      @continue="continueSurvey"
    />
    <SocialsSpinner v-if="showSpinner" />
    <transition :name="'fade'">
      <div v-if="!showPrompt && !showSpinner">
        <Header class="header fixed-top" />
        <main class="d-flex">
          <Sidebar class="sidebar" />
          <PostsList class="posts" />
        </main>
        <Footer class="footer fixed-bottom" />
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "../components/Instagram/Header.vue";
import Sidebar from "../components/Instagram/Sidebar.vue";
import PostsList from "../components/Instagram/PostsList.vue";
import Footer from "../components/Instagram/Footer.vue";

import SocialsPrompt from "../components/SocialsPrompt.vue";
import SocialsSpinner from "../components/SocialsSpinner.vue";

import {
  viewComponent,
  onNavigationBackToSetup,
} from "../mixins/instagramFacebookViewers";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Instagram",
  emits: ["finishedRecording"],
  components: {
    Header,
    Sidebar,
    PostsList,
    Footer,
    SocialsPrompt,
    SocialsSpinner,
  },
  mixins: [viewComponent, onNavigationBackToSetup],
  computed: {
    ...mapGetters("instagram", {
      showPrompt: "getShowPrompt",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
    }),
  },
  methods: {
    ...mapActions("instagram", ["getPosts"]),
    ...mapMutations("instagram", ["RESET_STATE", "SET_SHOW_PROMPT"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/instagram-styles.scss";
.instagram-container {
  background: rgb(255, 255, 255);
}
.header {
  height: $header-height;
}
main {
  min-height: 100%;
  background: rgb(255, 255, 255);
}
.sidebar {
  width: $sidebar-retracted-width;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.posts {
  flex-grow: 1;
}
.footer {
  height: $footer-height;
}
@media screen and (max-width: 768px) {
  // Mobile Only
  main {
    padding-top: $header-height;
    padding-bottom: $footer-height;
  }
  .sidebar {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1264px) {
  // Tablet Only
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
}
@media screen and (min-width: 1264px) {
  // Desktop Only
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
  .sidebar {
    width: $sidebar-extended-width;
  }
}
</style>
